import { useEffect } from "react";
import getSocket from "../../../helpers/socket";

export const useKanbanSocket = ({
    user,
    permissions,
    file,
    setFile,
    handleReset,
    handleAddNewTicket,
    handleUpdateUnreadTicket,
    handleUpdateTicket,
    handleDeleteTicketLane,
    showAllTickets,
    //userCanInteractWithAllGroups,
    blockNonDefaultConnections,
    ignoreUserConnectionForGroups,
    internalSelectedQueueIds,
    userAllowedToGroup
}) => {
    useEffect(() => {
        const socket = getSocket(true);
        const connectEvent = () => socket.emit("joinNotification");
        const localStorageQueueIds = window.localStorage.getItem('selectedQueueIds') ? JSON.parse(window.localStorage.getItem('selectedQueueIds')) : null;
        const userQueueIds = localStorageQueueIds ? localStorageQueueIds : user && user.queues ? user.queues.map(q => q.id) : [];
        const selectedQueueIds = internalSelectedQueueIds & internalSelectedQueueIds.length > 0 ? internalSelectedQueueIds : userQueueIds ? [-1, ...userQueueIds] : [-1];

        const shouldUpdateTicket = ticket => {
            //console.log('test queues', 'internal:', internalSelectedQueueIds, selectedQueueIds, selectedQueueIds.indexOf(ticket.queueId) > -1);
            return (!ticket.userId || ticket?.userId === user?.id || showAllTickets) &&
                (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&
                (ticket.status != "group" || userAllowedToGroup(ticket)) &&
                (!blockNonDefaultConnections ||
                    (ticket.status == 'group' && ignoreUserConnectionForGroups) ||
                    !user?.whatsappId || ticket.whatsappId == user?.whatsappId);
        }

 
        const ticketEvent = data => {
            //console.log(data.action, data, "ticket event");
            if (data.action === "updateUnread" && (!data.userId || data.userId === user.id)) {
                handleUpdateUnreadTicket(file, setFile, data.ticketId, 0);
            }
            if (data.action === "update") {
                handleUpdateTicket(file, setFile, data.ticket.gridStatus || data.ticket.status, String(data.ticket.id), data.ticket, shouldUpdateTicket(data.ticket));
            }
            if (data.action === "delete") {
                handleDeleteTicketLane(data.ticketId);
            }

            if (data.action === "resetGrid") {
                //     console.log('passed in here', 'test', 'resetGrid')
                handleReset();
            }
        };


        const appMessageEvent = (data) => {
            if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
                //console.log('was passed in here', data.ticket, 'test')
                handleUpdateTicket(file, setFile, data.ticket.gridStatus || data.ticket.status, String(data.ticket.id), data.ticket, shouldUpdateTicket(data.ticket));
            }
        };

        const contactEvent = data => {
            /*  if (data.action === "update") {
                  dispatch({
                      type: "UPDATE_TICKET_CONTACT",
                      payload: data.contact,
                      sortDir,
                      setInternalCount
                  });
              }*/
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);
        socket.on("appMessage", appMessageEvent);
        socket.on("contact", contactEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            //socket.off("ticket", ticketEvent);
            socket.disconnect();
        };
    }, [user, permissions, file, setFile]);
};
