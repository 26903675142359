import React, { useState, useEffect, useContext } from "react";
import { useTheme, Modal, Box, Button } from "@mui/material";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { useHistory, useLocation } from 'react-router-dom';
import useTickets from "../../hooks/useTickets";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import TicketListItem from "../TicketListItem";
import TicketFilters from "./components/filters";
import { getLocalOptions } from "../../helpers/userOptions";
import getSocket from "../../helpers/socket";
import NewLaneFormTicket from "./components/newBlockTicket";
import Ticket from "../../components/Ticket/";
import ResolveTicketModal from "../MessagesList/ResolveTicketModal";
import CustomLaneHeader from "./components/laneHeader";
import TicketBlock from "../../components/Tasks/FormTask/ticketBlock";
import { FaArrowAltCircleRight, FaTimes } from "react-icons/fa";
import { useStyles } from "./styles/styles";
import { useKanbanSocket } from "./hooks/useSocket";
import {
    handleAddNewTicket,
    handleUpdateTicket,
    handleUpdateUnreadTicket,
    handleDeleteTicketLane,
} from "./handlers/handleTickets";
import { updateLaneState } from "./services/laneService";
import { fetchTicketsForLane } from "./services/ticketService";
import { cardStyle, handleNewFormFinish } from "./handlers/handleLanes";


const TicketsGridManager = () => {
    const classes = useStyles();
    const history = useHistory();
    const { info, isActive, hashKey } = useContext(SettingsContext);
    const [showBotList, setShowBotList] = useState(true);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const [reset, setReset] = useState(false);

    const localStorageQueueIds = window.localStorage.getItem('selectedQueueIds') ? JSON.parse(window.localStorage.getItem('selectedQueueIds')) : null;
    const userQueueIds = localStorageQueueIds ? localStorageQueueIds : user && user.queues ? user.queues.map(q => q.id) : [];
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds ? [-1, ...userQueueIds] : [-1]);


    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [disableAnchor, setDisableAnchor] = useState(false);
    const [fixedTickets, setFixedTickets] = useState([]);
    const [ticketsList, dispatch] = useState([]);
    const [searchParam, setSearchParam] = useState("");
    const [searchById, setSearchByTicketId] = useState(null);
    const [searchOnMessages, setSearchOnMessages] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [connectionIds, setConnectionIds] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [forceSearch, setForceSearch] = useState(false);
    const [unreadMessagesSearchMe, setUnreadMessagesSearchMe] = useState(false);
    const [unreadMessagesSearchClient, setUnreadMessagesSearchClient] = useState(false);
    const [lastClientActivityMe, setLastClientActivityMe] = useState(null);
    const [lastClientActivityClient, setLastClientActivityClient] = useState(null);
    const sortDir = sortAscending ? 'ASC' : 'DESC';
    const showAll = showAllTickets ? 'true' : 'false';
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState({ lanes: [] });
    const [resolveTicketModalOpen, setResolveTicketModalOpen] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [showWhatsappConnection, setShowWhatsappConnection] = useState(false);
    const hasPermissionQueue = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:show:without:queue");
    const hasPermissionAccept = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:accept:any");
    const hasPermissionAcceptBot = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:bot:accept:any");
    const hasPermissionViewLastMessage = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:item:historic:list");
    const hasPermissionViewLastMessageBot = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:item:bot:historic:list");
    const themeApp = useTheme();
    const modeTheme = themeApp.mode;
    const [allStatus, setListStatus] = useState(null);
    const location = useLocation();
    const [openLaneFormModal, setOpenLaneFormModal] = useState(false);
    const [openModalTicket, setOpenModalTicket] = useState(false);
    const [motive, setMotive] = useState('');
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState('');
    const [lastDraggedCloseTicketId, setLastDraggedCloseTicket] = useState(null);
    const [lastTransferedTicket, setLastTransferedTicket] = useState(null);
    const [lastTransferedTicketUser, setLastTransferedTicketUser] = useState('');
    const [lastTransferedTicketQueue, setLastTransferedTicketQueue] = useState('');
    const [openInfoModalTicket, setOpenInfoModalTicket] = useState(false);
    const hasPermissionDrag = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:kanban:move");
    const userCanInteractWithAllGroups = user.roleId === 1 || permissions.some((permission) => permission.key === "contacts:groups:see-all");
    const blockNonDefaultConnections = isActive('blockNonDefaultConnections');
    const ignoreUserConnectionForGroups = isActive('ignoreUserConnectionForGroups');
    const userAllowedToGroup = (ticket) => userCanInteractWithAllGroups ? true : ticket.contact.allowUsers.some((item) => item.id == user.id);
    const [isSearched, setIsSearched] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [selectedUnreadMessageOption, setSelectedUnreadMessage] = useState('');

    const handleReset = () => {
        //console.log(reset, 'test reset');
        //dispatch([]);
        setForceSearch(!forceSearch);
    }

    useEffect(() => {
        setOpenInfoModalTicket(true);
        //after 10seconds setOpenInfoModalTicket(false)
    }, [lastTransferedTicket])

    const handleshowAllTickets = () => {
        setUserIds([])
        setShowAllTickets(!showAllTickets)
    }

    const { tickets, hasMore, loading, count, listStatus } = useTickets({
        pageNumber,
        searchParam,
        searchOnMessages,
        searchById: searchById && Number(searchById),
        showAll,
        userIds: userIds && JSON.stringify(userIds),
        contactIds: contactIds && JSON.stringify(contactIds),
        connectionIds: connectionIds && JSON.stringify(connectionIds),
        statuses: selectedStatuses && JSON.stringify(selectedStatuses),
        dateFrom,
        dateTo,
        queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
        tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
        isGroup: null,
        sortDir: sortAscending ? 'ASC' : 'DESC',
        forceSearch,
        lastClientActivityClient,
        lastClientActivityMe,
        unreadMessagesSearchMe,
        unreadMessagesSearchClient,
        settingChange: hashKey,
        mode: 'kanban'
    });

    useEffect(() => {
        listStatus && setListStatus(listStatus)
        // console.log(listStatus, 'test', 'list   ')
    }, [listStatus]);

    useEffect(() => {
        if (tickets) {
            dispatch(tickets);
        }
    }, [tickets, sortDir, showAllTickets, pageNumber]);


    useEffect(() => {
        const match = /^\/tickets\/\d+$/.test(location.pathname);
        setOpenModalTicket(match);
    }, [location.pathname]);

    useEffect(() => {
        const localStorageStatuses = window.localStorage.getItem('selectedModeList');
        const selectedModeLocalList = localStorageStatuses ? JSON.parse(localStorageStatuses) : [];
        
        const hasPermissionPending = user.roleId === 1 || permissions.some(permission => permission.key === 'tickets:pending:all');
        const hasPermissionBot = user.roleId === 1 || permissions.some(permission => permission.key === 'bots:show:page:users');
    
        if (selectedModeLocalList?.length >= 1) {
            const updatedStatuses = selectedModeLocalList.filter((status) => {
                if (status === 'pending' && !hasPermissionPending) return false;
                if (status === 'bot' && !hasPermissionBot) return false;
                return true;
            });
            //console.log('testes from localstorage', updatedStatuses, 'statuses')
            setSelectedStatuses(updatedStatuses);
        } else {
      
            const defaultStatuses = [
                hasPermissionPending && 'pending',
                hasPermissionBot && 'bot',
                'paused',
                'open',
                'closed',
                'group',
            ].filter(Boolean); 
            //console.log('testes', defaultStatuses, 'statuses')
            setSelectedStatuses(defaultStatuses);
        }
    }, [user, permissions]);
    

    cardStyle(modeTheme);

    useEffect(() => {
        if (ticketsList && listStatus) {

            const laneStyle = (modeTheme) => ({
                width: 280,
                backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#e3e3e3',
                borderRadius: 10,
                color: modeTheme === 'dark' ? '#fff' : '#000',
                height: '80vh'
            });



            const createLane = (id, title, filteredTickets, modeTheme, selectedStatuses) => ({
                id,
                title,
                label: String(filteredTickets.length),
                editLaneTitle: true,
                disallowAddingCard: true,
                droppable: id === 'group' ? false : true,
                style: {
                    ...laneStyle(modeTheme),
                    display: selectedStatuses.includes(id) ? 'inline-flex' : 'none'
                },
                cards: filteredTickets.map((ticket, index) => ({
                    id: ticket.id,
                    label: `Ticket nº ${ticket.id}`,
                    CustomComponent: (
                        <TicketListItem
                            disableAnchor={disableAnchor}
                            ticket={ticket}
                            fixedTickets={fixedTickets}
                            key={ticket.id}
                            showWhatsappConnection={showWhatsappConnection}
                            isChecked={checkedItems.includes(ticket.id)}
                            //onCheckboxChange={handleCheckboxChange}
                            mode={"grid"}
                        />
                    ),
                    ticket: ticket,
                    updatedAt: ticket.updatedAt,
                    title: ticket.contact.name,
                    contact: ticket.contact,
                    status: ticket.gridStatus || ticket.status,
                    lastMessage: ticket.lastMessage,
                    user: ticket.user,
                    tags: ticket.tags,
                    queue: ticket.queue,
                    whatsapp: ticket.whatsapp,
                    type: ticket.type,
                    unreadMessages: ticket.unreadMessages,
                    draggable: true,
                    href: `/tickets/${ticket.id}`,
                    style: cardStyle(modeTheme),
                    currentPage: 1
                })),
            });


            const popularCards = () => {
                const lanes = listStatus.map(statusItem => {
                    // For each status (e.g., pending, bot, etc.)
                    const seenIds = new Set();
                    const filteredTickets = ticketsList.filter(ticket => {
                        const isMatchingStatus = ticket.gridStatus === statusItem.id ||
                            (!ticket.gridStatus && ticket.status === statusItem.id);

                        if (isMatchingStatus && !seenIds.has(ticket.id)) {
                            seenIds.add(ticket.id); // Mark the ticket as seen
                            return true;
                        }
                        return false; // Skip if the ticket doesn't match or is already seen
                    });

                    return createLane(
                        statusItem.id,
                        statusItem.title || statusItem.id,
                        filteredTickets,
                        modeTheme,
                        selectedStatuses
                    );
                });
                setFile({ lanes });
            };

            popularCards();
        }
        setReset(false);

    }, [reset, listStatus, showAllTickets, selectedQueueIds, modeTheme, selectedStatuses, sortAscending]);


    useEffect(() => {
        const socket = getSocket(true);
        const botEvent = data => {
            if (data.type === "create" || data.type === "update") {
                if (data.bot.first) {
                    setShowBotList(data.enabledBotStatus);
                }
            }
        }
        socket.on("bot", botEvent);
        return () => {
            socket.off("bot", botEvent);
        };
    }, []);

    const handleCloseModalLane = () => {
        setOpenLaneFormModal(false);
    }

    useKanbanSocket({
        user,
        permissions,
        file,
        setFile,
        handleReset,
        handleAddNewTicket,
        handleUpdateTicket,
        handleUpdateUnreadTicket,
        handleDeleteTicketLane,
        handleCloseModalLane,
        showAllTickets,
        //userCanInteractWithAllGroups,
        blockNonDefaultConnections,
        ignoreUserConnectionForGroups,
        userAllowedToGroup,
        internalSelectedQueueIds: selectedQueueIds
    })

    useEffect(() => {
        dispatch([]);
        setPageNumber(1);
    }, [dispatch, sortDir, forceSearch, hashKey]);

    //Paginate Kanban
    const [lanePageNumbers, setLanePageNumbers] = useState({});

    const paginate = async (requestedPage, laneId, event) => {
       // console.log(event, requestedPage, laneId)
        const currentPage = lanePageNumbers[laneId] || 1;

        try {
            const newTickets = await fetchTicketsForLane({
                pageNumber: currentPage + 1,
                laneId,
                showAll,
                userIds: userIds && JSON.stringify(userIds),
                contactIds: contactIds && JSON.stringify(contactIds),
                connectionIds: connectionIds && JSON.stringify(connectionIds),
                dateFrom,
                dateTo,
                queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
                tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
                sortDir: sortAscending ? 'ASC' : 'DESC',
                forceSearch,
                lastClientActivityClient,
                lastClientActivityMe,
                unreadMessagesSearchMe,
                unreadMessagesSearchClient,
            });


            //console.log('newTickets', newTickets)

            if (newTickets && newTickets.tickets.length > 0) {
                updateLaneState(file, laneId, newTickets.tickets, currentPage + 1, setFile, setLanePageNumbers, hasMore);
                return true; // Indicate that there are more items to load
            } else {
                return false; // Indicate that there are no more items to load
            }
        } catch (error) {
            console.error("Error fetching tickets:", error);
            return false; // Stop further loading in case of error
        }
    };


    const handleLaneDragEnd = async (startPos, endPos, lane) => {
        try {
            const reorderedLanes = [...file.lanes];
            const [movedLane] = reorderedLanes.splice(startPos, 1);
            reorderedLanes.splice(endPos, 0, movedLane);
            const updatedLanes = reorderedLanes.map((item, index) => ({
                id: item.id,
                position: index,
            }));

            if (updatedLanes) {
                await api.post(`/tickets/columns/change`, {
                    lanes: updatedLanes,
                });

                // console.log(response, updatedLanes, 'teest')
            }
        } catch (err) {
            console.log(err)
        }
    };

    const handleLaneTitleChange = async (laneId, newTitle) => {
        //console.log('test', newTitle);
        await api.put(`/tickets/status/change/${laneId}`, {
            title: newTitle,
            //oldTitle: lanes.find(lane => lane.id === laneId).title,
        });

        setFile((prevFile) => {
            const updatedLanes = prevFile.lanes.map((lane) =>
                lane.id === laneId ? { ...lane, title: newTitle } : lane
            );
            localStorage.setItem('kanbanLanes', JSON.stringify(updatedLanes));

            return { lanes: updatedLanes };
        });
    };

    const handleUpdateTicketStatus = async (e, oldStatus, status, userId, closeNow) => {

        let infoTicket = {
            reason: motive,
            amount: amount,
            notes: notes
        }

        try {
            await api.put(`/tickets/${lastDraggedCloseTicketId.id}`, {
                status: status,
                userId: userId || null,
                closeNow,
                infoTicket
            });
            if (status === "open") {
                history.push('/tickets');
                setTimeout(() => {
                    history.push(`/tickets/${lastDraggedCloseTicketId.id}`); //ver depois
                }, 0);
            }
            else {
                history.push("/tickets");
            }

            setResolveTicketModalOpen(false);
        } catch (err) {
            toastError(err)
        }
    };

    useEffect(() => {
        const options = getLocalOptions();
        setFixedTickets(options.fixedTickets || []);

        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {
                const { data } = await api.get("/whatsapp/show-whatsapp-connection");
                setShowWhatsappConnection(data.show);
            };
            fetchData();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);



    const handleClean = () => {
        setShowAllTickets(false);
        setSearchParam("");
        setSearchByTicketId(null);
        setUserIds([]);
        setContactIds([]);
        setSelectedTagIds([]);
        setConnectionIds([]);
        setDateFrom(null);
        setDateTo(null);
        window.localStorage.removeItem('selectedQueueIds');
        setSelectedQueueIds([]);
        setForceSearch(!forceSearch);
        //history.push(`/tickets/`);
        setSelectedUnreadMessage('');
        setIsSearched(false);
        //setShowSearchBar(false);
    };

    const handleSearch = () => {
        setForceSearch(!forceSearch);
        setIsSearched(true);
        setShowSearchBar(false);
    };

    const handleCloseModalTicket = () => {
        setOpenModalTicket(false);
        history.push('/tickets');
    }

    const handleCloseLastTransfered = () => {
        setLastTransferedTicket(null);
        setLastTransferedTicketUser('');
    }


    const handleResolveTicket = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        //  console.log(lastDraggedCloseTicketId, 'last')
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "closed", user?.id);
        handleReset();
    }

    const handleResolveTicketFeedback = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "feedback", user?.id);
        handleReset();

    }

    const handleResolveTicketNoMessage = (e, m) => {
        if (permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "closed", user?.id, true);
        handleReset();
    }

    const handleOnCloseResolveModal = () => {
        setResolveTicketModalOpen(false);
        handleReset();
    }

    return (
        <>
            {(lastDraggedCloseTicketId && ticketsList && ticketsList.length >= 1) &&
                <ResolveTicketModal
                    open={resolveTicketModalOpen}
                    onClose={handleOnCloseResolveModal}
                    onAccept={handleResolveTicket}
                    onAcceptFeedback={handleResolveTicketFeedback}
                    onAcceptNoEndMessage={handleResolveTicketNoMessage}
                    ticket={lastDraggedCloseTicketId}
                    motive={motive}
                    amount={amount}
                    notes={notes}
                    setMotive={setMotive}
                    setAmount={setAmount}
                    setNotes={setNotes}
                />
            }


            <Modal
                open={openModalTicket}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseModalTicket();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
                    <Ticket closeModal={handleCloseModalTicket} />
                </Box>
            </Modal>

            <Modal
                open={openLaneFormModal}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseModalLane();
                    }
                }}>
                <Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
                    <NewLaneFormTicket onCancel={handleCloseModalLane}
                        onSave={(data) => handleNewFormFinish(data, selectedStatuses, setSelectedStatuses, handleCloseModalLane)} />
                </Box>
            </Modal>
            <div style={{ width: '100%', zIndex: 9 }}>
                <TicketFilters
                    allStatus={allStatus}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    searchById={searchById}
                    userIds={userIds}
                    setUserIds={setUserIds}
                    contactIds={contactIds}
                    setContactIds={setContactIds}
                    setSearchByTicketId={setSearchByTicketId}
                    selectedQueueIds={selectedQueueIds}
                    setSelectedQueueIds={setSelectedQueueIds}
                    searchParam={searchParam}
                    setSearchParam={setSearchParam}
                    selectedStatuses={selectedStatuses}
                    setSelectedStatuses={setSelectedStatuses}
                    selectedTagIds={selectedTagIds}
                    setSelectedTagIds={setSelectedTagIds}
                    showAllTickets={showAllTickets}
                    setShowAllTickets={handleshowAllTickets}
                    sortAscending={sortAscending}
                    setSortAscending={setSortAscending}
                    connectionIds={connectionIds}
                    setConnectionIds={setConnectionIds}
                    selectModeList={selectedStatuses}
                    setSelectModeList={setSelectedStatuses}
                    forceSearch={forceSearch}
                    setForceSearch={setForceSearch}
                    handleAddBlock={() => setOpenLaneFormModal(true)}
                    handleClean={handleClean}
                    isSearched={isSearched}
                    handleSearch={handleSearch}
                    showSearchBar={showSearchBar}
                    setShowSearchBar={setShowSearchBar}
                    selectedUnreadMessageOption={selectedUnreadMessageOption}
                    setSelectedUnreadMessage={setSelectedUnreadMessage}
                />

            </div>
            <div className={classes.root}>
                <Board
                    data={file}
                    draggable={hasPermissionDrag}
                    editable
                    canAddLanes
                    components={{
                        Card: (item, index) => (
                            <TicketListItem disableAnchor={disableAnchor}
                                ticket={item}
                                fixedTickets={fixedTickets}
                                key={index}
                                showWhatsappConnection={showWhatsappConnection}
                                isChecked={checkedItems.includes(item.id)}
                                //onCheckboxChange={handleCheckboxChange}
                                mode={"grid"}
                                acceptPermission={hasPermissionAccept}
                                acceptPermissionBot={hasPermissionAcceptBot}
                                viewLastMessage={hasPermissionViewLastMessage}
                                viewLastMessageBot={hasPermissionViewLastMessageBot}
                            />
                        ),
                        LaneHeader: ({ label, cards, title, id }) => (
                            <CustomLaneHeader label={label} cards={cards} title={title} laneId={id}
                                selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses}
                            />
                        ),
                        NewLaneSection: ({ t, onClick }) => (
                            <></>
                        ),
                        NewLaneForm: (props) => (
                            <></>
                        ),
                    }}
                    handleLaneDragEnd={handleLaneDragEnd}
                    handleDragEnd={async (ticketId, sourceLaneId, targetLaneId) => {
                        let patternLanes = ['open', 'bot', 'pending', 'paused', 'group', 'closed'];


                        //console.log(sourceLaneId, 'test', targetLaneId)
                        if (targetLaneId === 'closed') {
                            let ticket = ticketsList.filter(ticket => ticket.id === Number(ticketId))[0];
                            setLastDraggedCloseTicket(ticket);
                            setResolveTicketModalOpen(true);
                            return false; // Prevents the drag-drop operation for 'closed'
                        }

                        if (targetLaneId === 'group' || sourceLaneId === 'group') {
                            return false; // Prevents drag-drop for 'group'
                        }

                        if (!patternLanes.includes(targetLaneId) || !patternLanes.includes(sourceLaneId)) {
                            //console.log(sourceLaneId, targetLaneId, 'test')
                            try {
                                const response = await api.put(`/tickets/${ticketId}/${sourceLaneId}/${targetLaneId}`);
                                //console.log(response, 'test')
                                if (response.status === 200) {
                                    toast.success('Atendimento atualizado com Sucesso!');
                                } else {
                                    toast.error('Não foi possivel mover esse atendimento!');
                                    handleReset();
                                    return false;

                                }
                            } catch (err) {
                                toast.error('Não foi possivel mover esse atendimento!');
                                handleReset();
                                console.error(err);
                                return false;
                            }
                        } else {
                            try {
                                //VEEEEER AQUI AMANHA RETORNO ERRO
                                const response = await api.put(`/tickets/${ticketId}/${sourceLaneId}/${targetLaneId}`);
                                //console.log(response, 'test2')
                                if (response.status === 200) {
                                    // console.log(response, 'response')
                                    //toast.success('Atendimento atualizado com Sucesso!');
                                    if (sourceLaneId === 'closed' || sourceLaneId === 'pending' || sourceLaneId === 'bot' ||
                                        targetLaneId === 'pending' || targetLaneId === 'bot') {
                                     //   handleReset();
                                    }
                                }
                                else {
                                    toast.error('Não foi possivel mover esse atendimento!');
                                    handleReset();
                                    return false;
                                }
                            } catch (err) {
                                toast.error('Não foi possivel mover esse atendimento!');
                                console.error(err);
                                handleReset();
                                return false;
                            }
                        }
                    }}
                    style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)', borderRadius: 10 }}
                    className={classes.board}
                    onLaneUpdate={(laneId, data) => handleLaneTitleChange(laneId, data.title)}
                    onLaneScroll={(requestedPage, laneId, event) => paginate(requestedPage, laneId, event)}
                    shouldLanePaginate={() => false}
                />
                {lastTransferedTicket && lastTransferedTicket.id && lastTransferedTicketUser !== user.name ?
                    <div className={`${classes.boxTransferedTicket} boxTransfered`}>
                        <h5 style={{ marginBottom: 10 }}>Um Atendimento foi transferido para {lastTransferedTicketQueue ? ' seu setor' : ' você'}!</h5>
                        <p className={classes.paragraphTransfered}>
                            {lastTransferedTicketQueue ? lastTransferedTicketQueue :
                                <>{lastTransferedTicketUser}  < FaArrowAltCircleRight /> {user.name}</>
                            }
                        </p>
                        <TicketBlock item={{ ticket: lastTransferedTicket }} taskItem={true} ticketItem={true} />
                        <Button variant="contained" color="error" className={classes.boxTransferedTicketIconClose}
                            onClick={handleCloseLastTransfered}
                        >
                            <FaTimes size={14} />
                        </Button>
                    </div>
                    : <></>
                }
            </div>
        </>
    );

}
export default TicketsGridManager;