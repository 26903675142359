import React, { useRef, useState, useEffect } from 'react';
import { Button, Slider, IconButton, Typography } from '@mui/material';
import { PlayArrow, Pause, GetApp, VolumeUp, VolumeOff } from '@mui/icons-material';
//import './audio.css';
import { useTheme } from "@mui/material";

const AudioPro = ({ audio }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const apiURL = process.env.REACT_APP_BACKEND_URL;
  const theme = useTheme();

  const audioSrc = audio;

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
      setCurrentTime(audio.currentTime);
    };

    const setAudioDuration = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', setAudioDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', setAudioDuration);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * newValue;
    setProgress(newValue);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = audioSrc; // Use the audio URL directly
    link.target = '_blank';
    link.download = audioSrc.split('/').pop() || 'download.wav'; // Extract filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const changePlaybackRate = () => {
    const currentRateIndex = playbackRates.indexOf(playbackRate);
    const newRate = playbackRates[(currentRateIndex + 1) % playbackRates.length];
    audioRef.current.playbackRate = newRate;
    setPlaybackRate(newRate);
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const getFormattedTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <span style={{ display: 'flex', maxWidth: 300, alignItems: 'center', gap: '10px', backgroundColor: theme.mode === 'dark' ? '#333' : '#f9f9f9', padding: '10px', borderRadius: '5px', color: theme.mode === 'dark' ? '#fff' : '#333', }}>
      <IconButton onClick={togglePlayPause} variant="contained" color="primary" style={{ color: theme.mode === 'dark' ? '#fff' : '#333' }}>
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>
      <Typography variant="body2">{!isPlaying ? getFormattedTime(duration) : getFormattedTime(currentTime)}</Typography>
      <Slider
        value={progress}
        onChange={handleSliderChange}
        style={{ flex: 1, color: theme.mode === 'dark' ? '#fff' : '#333', width: 120, marginLeft: 5, marginRight: 5 }}
      />
      <Button onClick={changePlaybackRate} variant="contained" color="primary" size='small' style={{ minWidth: 24 }}>
        {playbackRate}x
      </Button>
      {/*<IconButton onClick={toggleMute} style={{ color: '#fff' }} size='small'>
        {isMuted ? <VolumeOff /> : <VolumeUp />}
      </IconButton>*/}
      <IconButton onClick={handleDownload} style={{ color: theme.mode === 'dark' ? '#fff' : '#333' }} size='small'>
        <GetApp />
      </IconButton>
      <audio ref={audioRef} src={audioSrc} style={{ display: 'none' }} />
    </span>
  );
};

export default AudioPro;