export const updateLaneState = (file, laneId, newTickets, nextPage, setFile, setLanePageNumbers, hasMore) => {
    setFile((prevFile) => {
      const updatedFile = {
        ...prevFile,
        lanes: prevFile.lanes.map((lane) =>
          lane.id === laneId
            ? { ...lane, cards: [...lane.cards, ...newTickets], currentPage: nextPage }
            : lane
        ),
      };
      return updatedFile;
    });
    setLanePageNumbers((prev) => ({ ...prev, [laneId]: nextPage }));
};
  