import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(theme => ({
    logo: {
        height: "46px",
        [theme.breakpoints.down('md')]: {
            width: "auto",
            height: "46px",
        }
    },
}));


const Logo = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { getSettingValue } = useContext(SettingsContext);
    const logo = getSettingValue('mainLogo');
    const darkLogo = getSettingValue('darkLogo');

    return (
        <>
            {theme.mode === 'dark' && darkLogo ?
            <img className={classes.logo} 
            src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${darkLogo}`}
            alt="Logo" /> 
            : theme.mode === 'light' && logo ? 
            <img className={classes.logo} 
            src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${logo}`}
            lt="Logo" />:
            <img className={classes.logo} src="/logo-black.png" alt="Logo" />
        }
        </>
    )
}

export default Logo;