const messages = {
	es: {
		translations: {
			search: {
				search: "Búsqueda avanzada",
			},
			signup: {
				title: "Registro",
				toasts: {
					success:
						"¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
					fail: "Error creando el usuario. Verifica la data reportada.",
				},
				form: {
					name: "Nombre",
					email: "Correo Electrónico",
					password: "Contraseña",
				},
				buttons: {
					submit: "Regístrate",
					login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
				},
			},
			columnName: {
                pending: "Pendiente",
				paused: "En Pausa",
				open: "Abierto",
				closed: "Cerrado",
				group: "Grupos",
            },
			login: {
				title: "Inicio de Sesión",
				download: "Descarga nuestra aplicación",
				form: {
					email: "Correo Electrónico",
					password: "Contraseña",
				},
				buttons: {
					submit: "Ingresa",
					register: "¿No tienes cuenta? ¡Regístrate!",
				},
				unable: "Actualmente no puedes acceder",
				nextTime: "La próxima vez será en:"
			},
			auth: {
				toasts: {
					success: "¡Inicio de sesión exitoso!",
				},
			},
			dashboard: {
				charts: {
					perDay: {
						title: "Tickets hoy: ",
					},
				},
			},
			connections: {
				title: "Conexiones",
				toasts: {
					deleted:
						"¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
					disconnectTitle: "Desconectar",
					disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
				},
				buttons: {
					add: "Agrega WhatsApp",
					disconnect: "Desconectar",
					tryAgain: "Inténtalo de nuevo",
					qrcode: "QR CODE",
					newQr: "Nuevo QR CODE",
					connecting: "Conectando",
				},
				toolTips: {
					disconnected: {
						title: "No se pudo iniciar la sesión de WhatsApp",
						content:
							"Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
					},
					qrcode: {
						title: "Esperando la lectura del código QR",
						content:
							"Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
					},
					connected: {
						title: "Conexión establecida",
					},
					timeout: {
						title: "Se perdió la conexión con el teléfono celular",
						content:
							"Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
					},
				},
				table: {
					name: "Nombre",
					status: "Estado",
					lastUpdate: "Última Actualización",
					default: "Por Defecto",
					actions: "Acciones",
					session: "Sesión",
				},
			},
			whatsappModal: {
				title: {
					add: "Agrega WhatsApp",
					edit: "Edita WhatsApp",
				},
				form: {
					name: "Nombre",
					default: "Por Defecto",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "WhatsApp guardado satisfactoriamente.",
			},
			qrCode: {
				message: "Lée el código QR para empezar la sesión.",
			},
			contacts: {
				title: "Contactos",
				toasts: {
					deleted: "¡Contacto borrado satisfactoriamente!",
					synced: "¡Contacto sincronizado correctamente!",
				},
				searchPlaceholder: "Buscar...",
				confirmationModal: {
					deleteTitle: "Borrar",
					importTitlte: "Importar contactos",
					deleteMessage:
						"¿Estás seguro que deseas borrar este contacto? Todos los tickets relacionados se perderán.",
					importMessage:
						"¿Quieres importar todos los contactos desde tu teléfono?",
				},
				buttons: {
					import: "Importar",
					add: "Agregar",
				},
				table: {
					name: "Nombre",
					whatsapp: "WhatsApp",
					email: "Correo Electrónico",
					actions: "Acciones",
					tags: "Tags"
				},
			},
			shortcut_messages: {
				title: "Mensagens Pré-definidas",
				toasts: {
					deleted: "Mensagem pré-definida excluída com sucesso!",
				},
				searchPlaceholder: "Pesquisar...",
				confirmationModal: {
					deleteTitle: "Deletar ",
					deleteMessage:
						"Tem certeza que deseja deletar esta mensagem pré-definida?",
				},
				buttons: {
					add: "Adicionar",
					import: "Importar",
					export: "Exportar"
				},
				table: {
					name: "Nome",
					content: "Conteúdo",
					queue: "Setores",
					actions: "Ações",
					file: "Arquivo"
				},
			},
			scheduled_messages: {
				title: "Mensagens Programadas",
				toasts: {
					deleted: "Mensagem programada excluída com sucesso!",
				},
				searchPlaceholder: "Pesquisar...",
				confirmationModal: {
					deleteTitle: "Deletar ",
					deleteMessage:
						"Tem certeza que deseja deletar esta mensagem programada?",
				},
				buttons: {
					add: "Adicionar Mensagem programada",
				},
				table: {
					date: "Agendada para",
					content: "Conteúdo",
					sent: "Enviada em",
					actions: "Ações",
					to: "Para"
				},
			},
			tags: {
				title: "Tags",
				toasts: {
					deleted: "Tag excluída com sucesso!",
				},
				searchPlaceholder: "Pesquisar...",
				confirmationModal: {
					deleteTitle: "Deletar ",
					deleteMessage:
						"Tem certeza que deseja deletar esta tag?",
				},
				buttons: {
					add: "Adicionar Tag",
				},
				table: {
					name: "Nome",
					content: "Conteúdo",
					actions: "Ações",
					color: "Cor",
					contacts: "Número de contatos"
				},

			},
			addNoteModal: {
				title: {
					add: "Agregar nota",
					edit: "Editar nota",
				},
				form: {
					mainInfo: "Detalles del contacto",
					extraInfo: "Información adicional",
					title: "Título",
					content: "Mensaje",
					number: "Número de Whatsapp",
					email: "Correo Electrónico",
					extraName: "Nombre del Campo",
					extraValue: "Valor",
				},
				buttons: {
					addExtraInfo: "Agregar información",
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Contacto guardado satisfactoriamente.",
			},
			addTaskModal: {
				title: {
					add: "Agregar tarefa",
					edit: "Editar tarefa",
					column: {
						add: "Adicionar coluna",
						edit: "Editar coluna"
					}
				},
				form: {
					mainInfo: "Detalles del contacto",
					extraInfo: "Información adicional",
					title: "Título",
					content: "Mensaje",
					number: "Número de Whatsapp",
					email: "Correo Electrónico",
					extraName: "Nombre del Campo",
					extraValue: "Valor",
				},
				buttons: {
					addExtraInfo: "Agregar información",
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Contacto guardado satisfactoriamente.",
				removed: "Contacto borrado satisfactoriamente.",
			},
			contactModal: {
				title: {
					add: "Agregar contacto",
					edit: "Editar contacto",
					attendants: "Atendentes"
				},
				form: {
					mainInfo: "Detalles del contacto",
					extraInfo: "Información adicional",
					name: "Nombre",
					number: "Número de Whatsapp",
					email: "Correo Electrónico",
					block: "Bloquear",
					customer_portfolio: "Portafolio de Clientes.",
					queue: "Setor",
					attendant: "Atendente",
					extraName: "Nombre del Campo",
					extraValue: "Valor",
				},
				import: {
					type_import: "Elija el tipo de importación",
					import_whats: "Importar contactos de WhatsApp",
					import_file: "Importar contactos desde archivo"
				},
				buttons: {
					addExtraInfo: "Agregar información",
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Contacto guardado satisfactoriamente.",
			},
			tagModal: {
				title: {
					add: "Adicionar tag",
					edit: "Editar tag",
				},
				form: {
					mainInfo: "Dados da tag",
					syncInfo: "¿Quieres sincronizar el contacto?",
					name: "Nome",
					color: "Cor"
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
					okSync: "Sincronizar",
				},
				success: "Tag salva com sucesso.",
			},
			shortcutMessageModal: {
				title: {
					add: "Adicionar mensagem pré-definida",
					edit: "Editar mensagem pré-definida",
					info: "Para usar mensajes, escribe '/' mientras escribes; puedes filtrarlos ingresando el nombre o contenido del mensaje"
				},
				form: {
					mainInfo: "Dados da mensagem pré-definida",
					extraInfo: "Informações adicionais",
					name: "Nome",
					content: "Conteúdo",
				},
				buttons: {
					addExtraInfo: "Adicionar informação",
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Mensagem pré-definida salva com sucesso.",
			},
			scheduledMessageModal: {
				title: {
					add: "Adicionar mensagem programada",
					edit: "Editar mensagem programada",
				},
				form: {
					mainInfo: "Dados da mensagem programada",
					extraInfo: "Informações adicionais",
					date: "Programar para:",
					content: "Conteúdo",
					contactId: "Contato"
				},
				buttons: {
					addExtraInfo: "Adicionar informação",
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Mensagem programada salva com sucesso.",
			},
			queueModal: {
				title: {
					add: "Agregar cola",
					edit: "Editar cola",
				},
				form: {
					name: "Nombre",
					color: "Color",
					greetingMessage: "Mensaje de saludo",
				},
				buttons: {
					okAdd: "Añadir",
					okEdit: "Ahorrar",
					cancel: "Cancelar",
				},
			},
			promptAiModal: {
				title: {
					add: "Enviar un mensaje",
				},
				form: {
					prompt: "¿Qué deseas?",
				},
				buttons: {
					okAdd: "Agregar",
					okSearch: "Pesquisar",
					generateImage: "Gerar Imagen",
					saveImage: "Salvar Imagen",
					reload: "Recargar",
					copy: "Copiar",
					close: "Cerrar",
					cancel: "Cancelar",
				},
			},
			speechtoTextAiModal: {
				title: {
					add: "Dictado a texto",
				},
				form: {
					prompt: "¿Qué deseas?",
				},
				buttons: {
					okAdd: "Agregar",
					okSearch: "Transcribir",
					reload: "Recargar",
					copy: "Copiar",
					cancel: "Cancelar",
				},
			},
			translateModal: {
				title: {
					add: "Tradução",
				},
				form: {
					prompt: "¿Qué deseas?",
				},
				buttons: {
					okAdd: "Agregar",
					okSearch: "Traduzir",
					reload: "Recargar",
					copy: "Copiar",
					close: "Fechar",
					cancel: "Cancelar",
				},
			},
			searchModal: {
				title: {
					add: "Buscar",
				},
				form: {
					label: "Digite sua busca",
					prompt: "¿Qué significa esto?",
				},
				buttons: {
					okAdd: "Agregar",
					okSearch: "Buscar",
					reload: "Recargar",
					copy: "Copiar",
					close: "Fechar",
					cancel: "Cancelar",
				},
			},
			promptImageModal: {
				title: {
					add: "Buscar en imagen",
				},
				form: {
					label: "Digite sua busca",
					prompt: "¿Podrías mostrarme detalles sobre la imagen?",
				},
				buttons: {
					okAdd: "Agregar",
					okSearch: "Buscar",
					reload: "Recargar",
					copy: "Copiar",
					close: "Fechar",
					cancel: "Cancelar",
				},
			},
			userModal: {
				title: {
					add: "Agregar usuario",
					edit: "Editar usuario",
				},
				form: {
					name: "Nombre",
					email: "Correo Electrónico",
					password: "Contraseña",
					profile: "Perfil",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Usuario guardado satisfactoriamente.",
			},
			chat: {
				noTicketMessage: "Selecciona un ticket para empezar a chatear.",
			},
			ticketsManager: {
				buttons: {
					newTicket: "Nuevo",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Linhas",
			},
			tickets: {
				toasts: {
					deleted: "El ticket en el que estabas ha sido borrado.",
				},
				notification: {
					message: "Mensaje de",
				},
				tabs: {
					open: { title: "Bandeja" },
					closed: { title: "Resueltos" },
					search: { title: "Buscar" },
					paused: { title: "En Pausa" },
				},
				search: {
					placeholder: "Buscar tickets y mensajes.",
					ticketId: "ID del ticket: ej. 15",
					connections: "Conexiones",
					users: "Usuarios",
					from: "De",
					contacts: "Contactos",
					to: "Até",
					unanswered: "Mensajes sin responder",
					unansweredDisabled: "Desactivado",
					unansweredClient: "Todas Mensajes sin responder del cliente",
					unansweredMe: "Todas Mensajes sin responder de mi parte",
					unansweredTimeClient: "Mensajes sin responder del cliente en un período de tiempo",
					unansweredTimeMe: "Mensajes sin responder de mi parte en un período de tiempo",
					tooltip: "Marcar para buscar también el contenido del mensaje (más lento)",

					selectedContacts: "contactos(s) seleccionado(s)",
					selectedConnections: "conexión(s) seleccionada(s)",
					selectedUsers: "usuario(s) seleccionado(s)",
					selectedTags: "tag(s) seleccionada(s)",
					selectedQueues: "cola(s) seleccionada(s)",
				},
				buttons: {
					showAll: "Todos",
					sortAscending: "Ordenar de más antiguo a más reciente",
					clear: "Limpiar",
					search: "Buscar"
				},
			},
			transferTicketModal: {
				title: "Transferir Ticket",
				fieldLabel: "Escriba para buscar usuarios",
				noOptions: "No se encontraron usuarios con ese nombre",
				buttons: {
					ok: "Transferir",
					cancel: "Cancelar",
				},
			},
			searchTicketModal: {
				title: "Buscar",
				fieldLabel: "Escribe para buscar mensaje",
				noOptions: "No se encontraron mensajes",
				buttons: {
					ok: "Buscar",
					cancel: "Cancelar",
				},
			},
			ticketsList: {
				pendingHeader: "Cola",
				assignedHeader: "Trabajando en",
				noTicketsTitle: "¡Nada acá!",
				noTicketsMessage:
					"No se encontraron tickets con este estado o término de búsqueda",
				buttons: {
					accept: "Acceptar",
				},
			},
			newTicketModal: {
				title: "Crear Ticket",
				fieldLabel: "Escribe para buscar un contacto",
				add: "Añadir",
				buttons: {
					ok: "Guardar",
					cancel: "Cancelar",
				},
			},
			mainDrawer: {
				listItems: {
					dashboard: "Dashboard",
					connections: "Conexiones",
					tickets: "Tickets",
					ticketskanban: "Tickets Kanban",
					contacts: "Contactos",
					queues: "Linhas",
					administration: "Admin",
					users: "Usuarios",
					settings: "Configuración",
					shortcut_messages: "Mensajes",
					scheduled_messages: "Planificación",
					tags: "Tags",
					bots: "Bots"
				},
				appBar: {
					user: {
						profile: "Perfil",
						logout: "Cerrar Sesión",
					},
				},
			},
			notifications: {
				noTickets: "Sin notificaciones.",
			},
			queues: {
				title: "Linhas",
				table: {
					name: "Nombre",
					color: "Color",
					greeting: "Mensaje de saludo",
					actions: "Comportamiento",
				},
				buttons: {
					add: "Agregar cola",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage:
						"¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
				},
			},
			queueSelect: {
				inputLabel: "Linhas",
			},
			roleSelect: {
				inputLabel: "Grupo de Permisos",
			},
			users: {
				title: "Usuarios",
				table: {
					name: "Nombre",
					email: "Correo Electrónico",
					profile: "Perfil",
					actions: "Acciones",
				},
				buttons: {
					add: "Agregar usuario",
				},
				toasts: {
					deleted: "Usuario borrado satisfactoriamente.",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage:
						"Toda la información del usuario se perderá. Los tickets abiertos de los usuarios se moverán a la cola.",
				},
			},
			settings: {
				success: "Configuración guardada satisfactoriamente.",
				title: "Configuración",
				tabs: {
					general: "Em geral",
					appointments: "Atenciones",
					internal_chat: "Charla interna",
					integration: "Integración",
					actions_by_batch: "Acciones por Lote",
					ai: "Inteligencia Artificial",
					distribution: "Distribución Automática"
				},
				settings: {
					notificationSound: {
						name: "Sonido de notificación general",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado",
						},
					},
					backgroundChat: {
						name: "Plano de fundo do chat",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
					chatNotificationSound: {
						name: "Sonido de notificación de mensaje",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado",
						},
					},
					voice: {
                        name: "Voz de Asistante Virtual",
                        options: {
                            enabled: "Habilitado",
                            disabled: "Deshabilitado",
                        },
                    },
					userCreation: {
						name: "Creación de usuarios",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado",
						},
					},
					groupMedia: {
						name: "Agrupar mídias (imagens e vídeos)",
						options: {
							enabled: "Ativado",
							disabled: "Desativado"
						}
					},
					showContacts: {
						name: "Mostrar seção Contatos para todos",
						options: {
							enabled: "Ativado",
							disabled: "Desativado (somente administrador)"
						}
					},
					showTags: {
						name: "Mostrar seção Tags para todos",
						options: {
							enabled: "Ativado",
							disabled: "Desativado (somente administrador)"
						}
					},
					showConnections: {
						name: "Mostrar seção Conexões para todos",
						options: {
							enabled: "Ativado",
							disabled: "Desativado (somente administrador)"
						}
					},
					showResolved: {
						name: "Mostrar Todos os Atendimentos Resolvidos para todos",
						options: {
							enabled: "Ativado",
							disabled: "Desativado (cada atendente vê apenas os seus atendimentos)"
						}
					},
					showWaiting: {
						name: "Mostrar Todos os Atendimentos Aguardando para todos",
						options: {
							enabled: "Ativado",
							disabled: "Disabled (only administrator)"
						}
					},
					showDeletedMessages: {
						name: "Mostrar mensagens deletadas",
						options: {
							enabled: "Ativado",
							disabled: "Disabled (only administrator)"
						}
					},
					showDashboard: {
						name: "Show dashboard section for all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showDisableSignature: {
						name: "Mostrar la opción para que el agente deshabilite la firma",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado (solo administrador)"
						}
					},
					showSurveyAfterFinish: {
						name: "Los agentes pueden finalizar la interacción sin enviar una encuesta de satisfacción, si está activada.",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado (solo administrador)"
						}
					},
					showCustomerPortfolio: {
						name: "Habilitar el Portafolio de Clientes",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado (solo administrador)"
						}
					},
					showMessagesNote: {
						name: "Mostrar notas de los mensajes de los tickets para todos los usuarios",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado (solo administrador)"
						}
					},
					showBot: {
						name: "Mostrar la sección de Bots para los usuarios",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado (solo administrador)"
						}
					},
					language: {
						name: "Idioma",
						options: {
							english: "Inglés",
							spanish: "Español",
							portuguese: "Portugués"
						}
					},
					appointments: {
						expiresTimeTicket: {
							name: "Tiempo de expiración del Atendimento",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						expiresTimeSurvey: {
							name: "Tiempo de expiración de la evaluación",
							helpText: "Tiempo de expiración (en minutos) cuando el sistema está esperando que el usuario responda a la evaluación (no puede ser 0).",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						expiresTimeBot: {
							name: "Tiempo de expiración del bot",
							helpText: "Tiempo de expiración (en minutos) cuando el sistema está esperando que el usuario responda al bot.",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						expiresMessage: {
							name: "Mensaje de expiración",
							helpText: "Mensaje a enviar al usuario cuando el atendimento expire.",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						limitTicketsOpen: {
							name: "Límite de atendimientos abiertos por usuario",
							helpText: "Cuántos atendimientos puede atender un atendente a la vez.",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						showTicketsTo: {
							name: "Mostrar histórico de atendimento para",
							options: {
								all: "Todos",
								admin: "Solo admin",
								queue: "Para usuarios del mismo setor"
							}
						},
						userCanInteractWithAllGroups: {
							name: "Usuario común puede ver todos los grupos",
							options: {
								enabled: "Habilitado",
								disabled: "Desabilitado (solo admin)"
							}
						},
						ticketsByConnection: {
							name: "Atendimientos por conexión",
							helpText: "Permitir más de un atendimento abierto para el mismo contacto, en conexiones diferentes",
						},
						historyByConnection: {
							name: "Histórico por conexión",
							helpText: "Mostrar histórico por contacto y por conexión (con esta opción deshabilitada, el histórico será el mismo para atendimientos para el mismo contacto independiente de la conexión)",
						},
						historyByConnectionAdmin: {
							name: "Histórico de conexión para administrador",
							helpText: "+Separa el histórico de conexión también para el administrador. Recordando que para tener efecto, la configuración anterior debe estar activa también",
						},
						canDeleteTickets: {
							name: "Puede eliminar atendimientos",
							helpText: "Si está deshabilitado, ningún usuario podrá eliminar atendimientos",
						},
						canDeleteMessages: {
							name: "Puede eliminar mensajes",
							helpText: "Si está deshabilitado, ningún usuario podrá eliminar mensajes",
						},
						forceDownload: {
							name: "Forzar descarga al hacer clic en el enlace de descarga de archivos",
							helpText: "En caso de estar habilitado, cuando el usuario realice la descarga del archivo, el navegador no intentará abrir el documento y siempre realizará la descarga. (Solo para archivos de hasta 500MB)",
						},
						showDownloadConfirmation: {
							name: "Mostrar pantalla de confirmación para descarga",
							helpText: "En caso de estar habilitado, cuando el usuario intente hacer descarga de archivo se le preguntará si realmente desea bajar el archivo",
						},
						blockNonDefaultConnections: {
							name: "Permite que el usuario vea atendimientos solo de su conexión predeterminada",
							helpText: "Si está habilitado, el usuario no verá atendimientos de otras conexiones, que no sea la que está en el campo 'Conexión predeterminada del usuario' en la edición de usuarios",
						},
						showLinks: {
							name: "Mostrar enlaces para los usuarios",
							helpText: "Si está habilitado, los usuarios podrán hacer clic en los enlaces normalmente",
						},
						enableGroupsNotifications: {
							name: "Habilitar notificaciones para Grupos",
							helpText: "Si está deshabilitado, no se enviarán notificaciones para grupos",
						},
						enableBotNotifications: {
							name: "Habilitar notificaciones para Bots",
							helpText: "Si está deshabilitado, no se enviarán notificaciones para bot",
						},
						ignoreUserConnectionForGroups: {
							name: "Ignorar conexión de usuario para grupos",
							helpText: "Si está habilitado, el usuario podrá ver grupos de cualquier conexión, siempre que se le permita ver los grupos especificados."
						}
					},
					chat: {
						enableInternalChat: {
							name: "Habilitar Chat Interno",
						},
					},
					batch: {
						cleanPending: {
							name: "Limpiar cola de espera (cerrar atendimientos)"
						},
						cleanOpen: {
							name: "Limpiar cola 'En Atendimento' (cierra los atendimientos sin enviar nada)"
						}
					},
					ai: {
						enableKeyChatGpt: {
							name: "CHATGPT clave de integración",
							helpText: "Coloca la Inteligencia artificial en tu atendimento, de forma simple solo insertar la clave de integración."
						},
					},
					autoDistribution: {
						enableDistribution: {
							name: "Habilitar distribución automática",
							helpText: "Al activar esta opción, tus mensajes se distribuirán automáticamente siguiendo la lista de usuarios a continuación"
						},
						limitsTicketsDistribution: {
							name: "Límite de atendimientos por usuario",
							helpText: "Configura un límite máximo de atendimientos por atendente"
						},
					},
					permissions: {
						add: "Agregar Función",
						name: "Nombre",
						back: "Voltar",
						userNumbers: "Número de usuarios",
						actions: "Acciones",
						confirmDelete: "Confirmar eliminación",
						confirmDeleteSpan: "¿Está seguro de que desea eliminar esta función? Esta acción no se puede deshacer."
					}
				},
			},
			messagesList: {
				header: {
					assignedTo: "Asignado a:",
					buttons: {
						return: "Devolver",
						resolve: "Resolver",
						reopen: "Reabrir",
						accept: "Aceptar",
						pause: "En Pausa",
						unpause: "Retomar",
					},
				},
			},
			messagesInput: {
				placeholderOpen: "Escribe un mensaje",
				placeholderClosed:
					"Vuelva a abrir o acepte este ticket para enviar un mensaje.",
				signMessage: "Firmar",
			},
			contactDrawer: {
				header: "Detalles del contacto",
				buttons: {
					edit: "Editar contacto",
				},
				extraInfo: "Otra información",
			},
			ticketOptionsMenu: {
				delete: "Borrar",
				transfer: "Transferir",
				confirmationModal: {
					title: "¿Borrar ticket #",
					titleFrom: "del contacto ",
					message:
						"¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
				},
				buttons: {
					delete: "Borrar",
					cancel: "Cancelar",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancelar",
				},
			},
			messageOptionsMenu: {
				delete: "Borrar",
				react: "Reaccionar",
				edit: "Corrigir",
				forward: "Reenviar mensaje",
				reply: "Responder",
				create_note: "Añadir la nota",
				create_task: "Crear Tarefa",
				search: "Buscar",
				translate: "Traduzir",
				transcribe: "Fala pra Texto",
				promptImage: "Buscar en imagen",
				textToSpeech: "Texto a voz",
				resumeDocument: "Resumir Documento",
				confirmationModal: {
					title: "¿Borrar mensaje?",
					message: "Esta acción no puede ser revertida.",
				},
				download_all: "Baixar Todos",
				download_zip: "Baixar arquivo zip"
			},

			dashboardHome: {
				general: "General",
				tickets: "Tickets",
				help: "Ayuda",
				filters: "Filtros",
				metrics: "Metricas",
				attendants: "Atendentes",
				connections: "Conexiones",
				contacts: "Contactos",
				groups: "Grupos",
				messages: "Mensajes",
				tags: "Tags",
				appointments_sents: "Agendados/Enviados",
				queues: "Setores",
				bots: "Bots",
				total: "Total",
				no_evaluation: "No hay evaluación",
				no_queue: "No hay setores",
				status: {
					resolved: "Resueltos",
					paused: "Pausados",
					open: "Abiertos",
					pending: "Pendientes",
					online: "En Atención",
					bot: "Bot"
				}
			},
			updatesModal: {
				title: "Actualizaciones",
				span: "En esta nueva versión, tenemos algunas novedades para ti",
				button: "Sí, entendí",
				dont_show: "No mostrar más"
			},
			webhooks: {
				title: "Webhooks (Beta)",
				table: {
					name: "Nombre",
					url: "URL del API",
					urlType: "Tipo de solicitud",
					headerString: "Encabezado (JSON, opcional)",
					active: "Activo",
					type: "Tipo",
					actions: "Acciones"
				},
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Agregar webhook",
				},
				toasts: {
					deleted: "Webhook eliminado con éxito.",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "¿Está seguro de que desea eliminar? La URL configurada ya no recibirá solicitudes del tipo seleccionado.",
				},
			},
			apikeys: {
				title: "Claves de API",
				table: {
				  name: "Nombre",
				  url: "URL de la API",
				  urlType: "Tipo de solicitud",
				  headerString: "Encabezado (JSON, opcional)",
				  active: "Activo",
				  type: "Tipo",
				  actions: "Acciones",
				  key: "Clave",
				},
				searchPlaceholder: "Buscar",
				buttons: {
				  add: "Generar Nueva Clave",
				  documentation: "Consultar Documentación",
				},
				addTitle: "Agregar nueva clave de API",
				buttonAdd: "Agregar",
				labelText: "Nombre de la clave",
				warning: "¡Atención! Solo se muestran aquí las claves de API creadas por su usuario",
				toasts: {
				  deleted: "Clave de API eliminada con éxito.",
				  success: "Clave de API copiada al portapapeles",
				},
				noapikeys: "No hay claves de API registradas.",
				confirmationModal: {
				  deleteTitle: "Eliminar",
				  deleteMessage: "¿Está seguro de que desea eliminar? La URL configurada ya no recibirá solicitudes del tipo seleccionado.",
				},
			  },
			iframes: {
				title: "Iframes (Beta)",
				table: {
					name: "Nombre",
					url: "URL",
					active: "Activo",
					actions: "Acciones"
				},
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Agregar iframe",
				},
				toasts: {
					deleted: "Iframe eliminado con éxito.",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteMessage: "¿Está seguro de que desea eliminar?",
				},
			},

			bots: {
				title: "Bot de Mensajes",
				clear: "Limpiar Bot",
				greeting: "Saludo",
				disable: "Desactivar Bot",
				enable: "Habilitar Bot",
				add: "Agregar Opción",
				options: "Opciones",
				action: "Acción para esta opción",
				message_after: "Mensaje después de seleccionar esta opción",
				exclude_confirmation: "¿Estás seguro de que deseas eliminar esta opción? Todas las listas secundarias también se eliminarán",
				select_queue: "Selecciona una cola",
				select_action: "Selecciona una acción",
				new_menu: "Nuevo Menú",
				go_queue: "Asignar a una Cola",
				go_user: "Asignar a un Agente",
				finish_ticket: "Finalizar la interacción",
				exclude_options: "¿Estás seguro de que deseas eliminar esta lista? Todas las opciones se eliminarán",
				click_edit: "Haz clic aquí para editar y activar",
				desactived: "El bot aún no está activado.",
				confirmation_delete: "¿Estás seguro de que deseas eliminar todo?"
			},
			backendErrors: {
				ERR_OPENAI: "Error: La integración con OpenAI falló. Por favor, verifica si la clave de integración es correcta y si hay créditos disponibles.",
				ERR_NO_OTHER_WHATSAPP:
					"Debe haber al menos una conexión de WhatsApp predeterminada.",
				ERR_NO_DEF_WAPP_FOUND:
					"No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
				ERR_WAPP_NOT_INITIALIZED:
					"Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
				ERR_WAPP_CHECK_CONTACT:
					"No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
				ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
				ERR_WAPP_DOWNLOAD_MEDIA:
					"No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
				ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
				ERR_USER_DISABLED: "Este usuário foi desativado.",
				ERR_SENDING_WAPP_MSG:
					"Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
				ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
				ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
				ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
				ERR_USER_CREATION_DISABLED:
					"La creación de usuarios fue deshabilitada por el administrador.",
				ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
				ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
				ERR_NO_SETTING_FOUND:
					"No se encontró ninguna configuración con este ID.",
				ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
				ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
				ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
				ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
				ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
				ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
				ERR_FETCH_WAPP_MSG:
					"Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS:
					"Este color ya está en uso, elija otro.",
				ERR_WAPP_GREETING_REQUIRED:
					"El mensaje de saludo es obligatorio cuando hay más de una cola.",
			},
		},
	},
};

export { messages };
