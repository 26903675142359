export const handleNewFormFinish = (data, selectedStatuses, setSelectedStatuses, handleCloseModalLane) => {
    //console.log(data, 'result')
    let lists = [...selectedStatuses, data.id];
    setSelectedStatuses(lists)
    localStorage.setItem('selectedModeList', JSON.stringify(lists))
    handleCloseModalLane();
    // window.location.reload();
}

export const cardStyle = (modeTheme) => ({
    whiteSpace: 'initial',
    backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#fff',
    border: 0,
    borderRadius: 15
});