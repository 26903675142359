import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        ...theme.scrollbarStylesSoft,
    },
    board: {
        ...theme.scrollbarStylesSoft,
    },
    button: {
        background: "#10a110",
        border: "none",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        borderRadius: "5px",
    },
    buttonAdd: {
        width: '100%',
        minWidth: 250,
        borderRadius: 5,
        border: '1px solid rgba(255,255,255,0.5)',
        fontWeight: 'bold',
        background: 'transparent',
        margin: '0px 10px'
    },
    boxTransferedTicket: {
        position: 'fixed',
        bottom: 13,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0 auto',
        left: 'calc(50% - 12.5%)',
        background: theme.mode === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
        padding: 10,
        borderRadius: 13,
        color: theme.mode === 'dark' ? '#fff' : '#000',
    },
    boxTransferedTicketIconClose: {
        position: 'absolute',
        top: -10,
        right: -15,
        width: 24,
        minWidth: 24,
        height: 24,
        boxShadow: 'none',
        borderRadius: 100,
        padding: 0
    },
    paragraphTransfered: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        paddingTop: 5,
        paddingBottom: 5,
        borderTop: theme.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
        borderBottom: theme.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
    }
}));