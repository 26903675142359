import React, { useState, useContext } from "react";
import { Modal, Box, Typography, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SettingBoolField from "../../../components/Setting/boolField";
import SettingTextField from "../../../components/Setting/textField";
import makeStyles from '@mui/styles/makeStyles';
import { SettingsContext } from "../../../context/Settings/SettingsContext";
import { i18n } from "../../../translate/i18n.js";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        background: 'transparent',
        marginBottom: 25,
        '&>div': {
            width: "100%"
        },
        boxShadow: 'none'
    },
    col50: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    ulTasks: {
        listStyleType: 'number'
    },
    liTasks: {
        padding: 10,
        marginBottom: 10,
        background: theme.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0, 0.05)',
        borderRadius: 10,
        fontSize: 14
    }
}))


export const KrolikSettings = ({ settings }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const history = useHistory();
    const handleOpen = (image) => {
        setCurrentImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImage("");
    };

    return (
        <>
            <span style={{ display: 'flex', alignItems: 'center', gap: 5, width: '100%' }}>
                <img src="/integrations/krolik.png" height={50} alt="Krolik" />
                {" Krolik"}
            </span>
            <div style={{ marginTop: 10, padding: 15, borderRadius: 20, background: 'rgba(0,0,0,0.01)' }}>
                {`Krolik VoIP é uma plataforma avançada de comunicação que oferece soluções robustas de telefonia via internet. 
                                    Ideal para empresas, a ferramenta proporciona chamadas de alta qualidade, recursos avançados como gravação,
                                    monitoramento de chamadas e integração com CRMs, otimizando a comunicação e gestão de equipes.`}<br />
                <a href="https://krolik.com.br" target="_blank">Site oficial</a>
            </div>

            <div className="flex" style={{ display: 'flex', gap: 5 }}>
                <div className={classes.col50} style={{ gap: 50 }}>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableKrolik" settings={settings}
                            label={"Habilitar Ligações com Krolik"}
                            helpText={""} />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableKrolikTranscript" settings={settings}
                            label={"Habilitar retorno de log de ligação no atendimento"}
                            helpText={"Ativando essa opção, é possivel qualificar atendimentos realizados"} />
                    </Paper>

                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableKrolikCallLogs" settings={settings}
                            label={"Habilitar transcrição chamadas"}
                            helpText={"Ativando essa opção, é possivel transcrever chamadas"} />
                    </Paper>

                    <Paper className={classes.paper} style={{ maxWidth: '50%', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <label>Krolik Servidor</label>
                        <span style={{width: '100%', display:'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5}}>
                            https://<SettingTextField name="serverKrolik" settings={settings}
                                helpText="ipbx" />.krolik.com.br
                        </span>
                    </Paper>
                </div>

                <div className={classes.col50}>
                    <ul className={classes.ulTasks}>
                        <li className={classes.liTasks}>
                            Contrate um ramal com o nosso parceiro Krolik e faça o download do softphone através do link abaixo: &nbsp;
                            <a href="https://www.microsip.org/download/private/Krolik-3.20.7.exe" target="_blank">Clique aqui</a>.
                        </li>
                        <li className={classes.liTasks}>
                            Instale o softphone clicando em "Avançar" e siga as etapas até concluir a instalação. Após isso, configure o softphone com os dados fornecidos pela Krolik.
                            Consulte os tutoriais abaixo para auxílio:
                            <a href="#" onClick={() => handleOpen("/krolik/tutorial-softphone1.webp")}> Passo 1</a> |
                            <a href="#" onClick={() => handleOpen("/krolik/tutorial-softphone2.webp")}> Passo 2</a>.
                        </li>
                        <li className={classes.liTasks}>
                            Habilite as configurações de ligações neste painel, ativando as opções e adicionando as informações fornecidas, como a URL do servidor (somente o nome do domínio, conforme o exemplo).
                            <br />
                            Edite um usuário em <a style={{ cursor: 'pointer' }} onClick={() => history.push('/users')}>Admin -> Usuários</a> adicione o ramal fornecido na configuração do usuário.
                            Sem essas configurações, não será possível realizar chamadas.
                        </li>
                        <li className={classes.liTasks}>
                            Pronto! A configuração foi concluída com sucesso. Agora, você pode clicar no ícone de telefone no atendimento, e o softphone irá tocar.
                            Ao clicar em "Aceitar", sua chamada será realizada.
                        </li>
                        <li className={classes.liTasks}>
                            Após o término da ligação, o log da chamada será retornado ao atendimento, contendo informações como detalhes da chamada, a gravação, e a possibilidade de transformar a gravação em texto.
                            Esta funcionalidade estará disponível caso a opção esteja ativada no painel e a chave do CHATGPT tenha sido configurada na aba de Inteligência Artificial.
                        </li>
                    </ul>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="tutorial-modal-title"
                aria-describedby="tutorial-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        maxWidth: "60%",
                        maxHeight: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="tutorial-modal-title" variant="h6" component="h2">
                        Tutorial do Softphone
                    </Typography>
                    <img
                        src={currentImage}
                        alt="Tutorial"
                        style={{
                            maxWidth: "80%",
                            maxHeight: "600px",
                            marginTop: "16px",
                            borderRadius: "8px",
                        }}
                    />
                </Box>
            </Modal>
        </>
    )
}
