
import { AttachFile, Delete } from "@mui/icons-material";
import ModalImageCors from "../../ModalImageCors";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import { IoMdTime } from 'react-icons/io';
import { MdCallMade, MdOutlineCancel, MdCallMissed, MdStart } from 'react-icons/md';
import { TiSortNumerically } from 'react-icons/ti';
import { TbPhoneCalling } from "react-icons/tb";
import AudioPro from "./audioPro";
import { Button } from "@mui/material";
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

const isImage = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
};

/*const handleNoteCreated = async (e) => {
    history.go(0);
};*/

const handleDownload = (file) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = file;
    downloadLink.download = file.split('/').pop();
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

function formatBrazilianDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const deleteMessageNote = async (id) => {
    try {
        await api.delete(`/notes/${id}`);
        //handleNoteCreated();
    } catch (err) {
        toastError(err);
    }
}


const getStatusMessage = (status) => {
    if (status === 'ANSWER' || 'ANSWERED') return 'Foi atendido';
    if (status === 'NO ANSWER') return 'Não atendido';
    return 'Cancelado'
}

const formatDuration = (seconds) => {
    //console.log(seconds, 'test seconds');
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} s`;
};


const renderStatusIcon = (status) => {
    if (status === 'ANSWER' || 'ANSWERED') return <MdCallMade style={{ marginRight: 5 }} />;
    if (status === 'NO ANSWER') return <MdCallMissed style={{ marginRight: 5 }} />
    return <MdOutlineCancel style={{ marginRight: 5 }} />
}




export const renderMessageNote = (id, position, user, messageNotes, classes, handleOpenAudioTranscribeModal, enableKrolikCallLogs, enableKrolikTranscript, ticketId) => {
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const handleOpenTranscribe = (event, item) => {
        event.preventDefault();
        handleOpenAudioTranscribeModal(item);
    }

    const filteredNotes = messageNotes.filter((note) => (note.messageId === id && note.ticketId === Number(ticketId)));
   // console.log('teste', id, filteredNotes, messageNotes, ticketId)
    return filteredNotes.map((note) => (
        <>
          
            <div key={note.id} id={note.messageId}
                className={classes.noteBlock}
                style={{
                    backgroundColor: note.color,
                    alignSelf: note.type === 'call' ? 'center' : position === 'right' ? 'flex-end' : 'flex-start'
                }}>


                {position === 'right' && note.type !== 'call' ? (
                    <>
                        <div
                            className={classes.arrowTop}
                            style={{
                                borderBottom: `15px solid ${note.color}`,
                                right: 30,
                            }}
                        ></div>
                    </>
                ) : position === 'left' && note.type !== 'call' ? (
                    <>
                        <div
                            className={classes.arrowTop}
                            style={{
                                borderBottom: `15px solid ${note.color}`,
                                left: 30,
                            }}
                        ></div>
                    </>
                ) : (<>
                </>)}
                <div className={classes.noteHeader}>
                    {note.type === 'call' && enableKrolikCallLogs ?
                        <h4 className={classes.noteHeaderTitle} style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                            <TbPhoneCalling size={18} />{note.title}
                        </h4>
                        :
                        <h5 className={classes.noteHeaderTitle}>{note.title}</h5>
                    }

                    {(user && user.profile == 'admin' || user.id === note.userId) && note.type !== 'call' ?
                        <Delete onClick={() => deleteMessageNote(note.id)} className={classes.DeleteIcon} /> : <></>}
                </div>
                {note.type === 'call' && enableKrolikCallLogs ? (
                    <p className={`${classes.noteContent} ripple-ticket`} style={{ color: '#000' }}>
                        <small style={{ display: 'block', marginBottom: 10 }}>Log de Chamada</small>
                        <span>
                            {renderStatusIcon(note.status)}
                            <strong>Status:</strong> {getStatusMessage(note.status)}
                        </span>
                        <br />
                        <span>
                            <TiSortNumerically style={{ marginRight: 5 }} />
                            <strong>Número discado:</strong> {note.phone}
                        </span>
                        <br />
                        <span>
                            <MdStart style={{ marginRight: 5 }} />
                            <strong>Ramal origem:</strong> {note.originRamal}
                        </span>
                        <br />
                        <span>
                            <IoMdTime style={{ marginRight: 5 }} />
                            <strong>Duração da Chamada:</strong> {formatDuration(note.duration)}
                        </span>
                        <br />
                        {note.url && note.url !== 'NULL' && enableKrolikTranscript && (
                            <span>
                                {/*<FaRegHandPointRight style={{ marginRight: 5 }} />*/}
                                <AudioPro audio={note.url} />
                                <Button variant="outlined" style={{ width: '100%', marginTop: 5,color: '#000', borderColor: '#000' }}
                                onClick={(event) => {handleOpenTranscribe(event, note.url)}}
                                >Transcrever Ligação</Button>
                            </span>
                        )}
                    </p>
                ) : (
                    <p className={classes.noteContent}>
                        {note.content}
                        {note.isAudio}
                    </p>
                )}

                {note.mediaUrl && note.type !== 'call' ?
                    note.isAudio ?
                        <audio controls>
                            <source src={`${apiURL}/public/${note.mediaUrl}`} type="audio/mp3"></source>
                        </audio> : isImage(note.mediaUrl) ? (
                            <div className={classes.linkFile} >
                                <ModalImageCors imageUrl={`${apiURL}/public/${note.mediaUrl}`} />

                            </div>

                        ) : (
                            <p className={classes.linkFile} onClick={() => handleDownload(`${apiURL}/public/${note.mediaUrl}`)}>
                                <AttachFile /> {note.mediaUrl}
                            </p>
                        ) : (
                        <></>
                    )}
                <div className={classes.noteBottom}>
                    <p style={{ fontSize: 13, padding: 0, width: '100%', textAlign: position ? 'right' : 'left', fontStyle: 'italic' }}>{formatBrazilianDateTime(note.createdAt)}</p>
                    <p style={{ fontSize: 13, padding: 0, width: '100%', textAlign: position ? 'right' : 'left', marginTop: 0, fontStyle: 'italic' }}>
                        {note.avatar && note.avatar.src ? (
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${note.avatar.src}`}
                                style={{ width: 24, height: 24, borderRadius: 100, marginRight: 10 }}
                            />) : (
                            <img src={`https://ui-avatars.com/api/?name=${note.username}`}
                                style={{ width: 24, height: 24, borderRadius: 100, marginRight: 10 }}
                            />
                        )}
                        {note.username ? note.username : ''}</p>
                </div>
            </div>
        </>
    ));
};
