import React, { useEffect, useState, useContext, useRef } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { FormHelperText, InputAdornment, TextField } from "@mui/material";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const SettingBranchLine = ({ userId, ramal }) => {
    const inputRef = useRef();
    const [currentValue, setCurrentValue] = useState(ramal);
    const { isActive } = useContext(SettingsContext);
    const isVoipActived = isActive("enableKrolik");

    useEffect(() => {
        setCurrentValue(ramal);
    }, [ramal]);

    const handleChangeSetting = async (e) => {
        const selectedValue = e.target.value;
        setCurrentValue(selectedValue);

        //console.log("passed in here front", userId, ramal, selectedValue);

        try {
            parseInt(selectedValue);
        } catch (err) {
            toast.error("Por favor, informe um número");
            return;
        }

        // Debounce logic
        clearTimeout(inputRef.current);
        inputRef.current = setTimeout(async () => {
            try {
                await api.put(`/users/${userId}/branchline`, {
                    value: selectedValue,
                });
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    };

    return (
        isVoipActived && (
            <>
                <TextField
                    label="Ramal Krolik"
                    variant="outlined"
                    id="ramal"
                    name="ramal"
                    type="number"
                    aria-describedby="ramal-helper"
                    value={currentValue}
                    onChange={handleChangeSetting}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src="/integrations/krolik.png" height={32} alt="Krolik" />
                            </InputAdornment>
                        ),
                    }}
                />
                <FormHelperText id="ramal-helper">
                    Adicione seu ramal para ativar ligações
                </FormHelperText>
            </>
        )
    );
};

export default SettingBranchLine;