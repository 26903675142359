import React, { useState } from 'react';
import { Button, useTheme, TextField } from "@mui/material";
import { FaPlus, FaTimes } from 'react-icons/fa';
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";
import { toast } from "react-toastify";
import toastError from "../../../errors/toastError";

const NewLaneFormTicket = ({ onSave, onCancel, item, order, boardId }) => {
    const [laneTitle, setLaneTitle] = useState('');
    const [doneType, setDoneType] = useState(false);

    const themeApp = useTheme();
    const modeTheme = themeApp.mode;


    const removeAccents = (text) => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const formatTitleToId = (title) => {
        return removeAccents(title.toLowerCase()).replace(/\s+/g, '-');
    };

    const handleSave = async () => {
        if (!laneTitle) {
            toast.error("Título é obrigatório!");
            return;
        } 

        try {
            const data = {
                id: formatTitleToId(laneTitle),
                title: laneTitle,
                oldTitle: laneTitle,
                order: order + 1
            };

            await api.post(`/tickets/status`, data);
            onSave(data);
            toast.success(i18n.t('Adicionado com sucesso!'));
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };



    return (
        <div className="block-add-new-column" style={{
            padding: 10,
            color: modeTheme === 'dark' ? '#fff' : '#000',
            backgroundColor: modeTheme === 'dark' ? '#000' : '#fff',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 260
        }}>
            <TextField
                variant='outlined'
                value={laneTitle}
                onChange={(e) => setLaneTitle(e.target.value)}
                label="Titulo do Bloco"
                height={50}
                style={{
                    width: '100%', marginBottom: 10, minHeight: 40
                }}
            />

            <div>
                {/*<FormControlLabel
            control={
              <Checkbox checked={doneType} onChange={handleDoneType} name="doneType" />
            }
            label="Bloco de Finalização"
            />*/}
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button variant='contained' size="small" onClick={handleSave} style={{ marginRight: 10 }}>
                    <FaPlus /> Adicionar
                </Button>
                <Button variant="outlined" size="small" onClick={onCancel}>
                    <FaTimes /> Cancelar
                </Button>
            </div>
        </div>
    );
}

export default NewLaneFormTicket;