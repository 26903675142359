export const handleAddNewTicket = (file, setFile, updatedData) => {
    //const laneId = updatedData.gridStatus || updatedData.status;
    //let cardToMove = { id: String(updatedData.id), ...updatedData };

    const updatedBoard = {
        ...file,
        lanes: file.lanes.map((lane) => ({
            ...lane,
            cards: lane.cards.map((card) =>
                card.id === Number(updatedData.id)
                    ? { ...updatedData, ...card }
                    : card
            ),
        })),
    };

    setFile(updatedBoard);
};


export const handleUpdateTicket = (file, setFile, laneId, ticketId, updatedData, shouldUpdateTicket) => {


    // Step 1: Find the card to update/move
    let cardToMove = file.lanes
        .flatMap((lane) => lane.cards)
        .find((card) => card.id === Number(ticketId)); // Ensure ticketId is treated as a string


    //console.log('newUpdating', laneId, ticketId, typeof (ticketId), 'cardToMove:', cardToMove, 'updatedData:', updatedData, file);

    if (!cardToMove) {
        const { status } = updatedData;
        cardToMove = { id: ticketId, ...updatedData };
        // Add only if the status is 'pending' or 'bot'
        if (status === 'pending' || status === 'bot') {
            console.warn(`Card with id ${ticketId} not found in any lane! Adding a new card.`);
            cardToMove = { id: ticketId, userId: null, ...updatedData }; // Create a new card if it doesn't exist
        } else if (status !== 'pending' || status !== 'bot') {
            cardToMove = { id: ticketId, ...updatedData };
        } else {
            console.error(`Card with id ${ticketId} not found, and status is not 'pending' or 'bot'. Skipping.`);
            return;
        }
    } else {
        //console.log('Card to move found:', cardToMove);
    }

    //  console.log('Card to move:', cardToMove);

    const { gridStatus, status, id: newId, lastMessage, unreadMessages, ...otherUpdates } = updatedData; // Extract updated id separately
    const hasSignificantChange = Object.keys(otherUpdates).some(
        (key) => cardToMove[key] !== updatedData[key]
    );



    const updatedBoard = {
        ...file,
        lanes: file.lanes.map((lane) => ({
            ...lane,
            cards: [...lane.cards], // Clone cards array to avoid mutation
        })),
    };


    if (!hasSignificantChange) {
        console.log('No changes detected. Skipping update.');
        updatedBoard.lanes = updatedBoard.lanes.map((lane) => {
            if (lane.cards.some((card) => card.id === ticketId)) {
                return {
                    ...lane,
                    cards: lane.cards.filter((card) => card.id !== ticketId),
                };
            }
            return lane;
        });

       

    } else {
        if (
            cardToMove.status !== updatedData.status ||
            cardToMove.gridStatus && cardToMove.gridStatus !== updatedData.gridStatus || !cardToMove.gridStatus && updatedData.gridStatus
        ) {

            const targetLane = updatedBoard.lanes.find((lane) => lane.id === laneId);

            if (targetLane) {
                /*console.log(
                    'Passed in here:',
                    'cardToMove.status', cardToMove.status, 'updatedData.status', updatedData.status,
                    'cardToMove.gridStatus', cardToMove.gridStatus, 'updatedData.gridStatus', updatedData.gridStatus
                );*/

               
                updatedBoard.lanes = updatedBoard.lanes.map((lane) => {
                    const isCardInThisLane = lane.cards.some((card) => card.id === cardToMove.id);
                    if (isCardInThisLane) {
                     // console.log(`Removing card ${cardToMove.id} from lane ${lane.id}`);
                    }
                    return {
                        ...lane,
                        cards: lane.cards.filter((card) => card.id !== cardToMove.id), 
                    };
                });

                
                const updatedTargetLane = updatedBoard.lanes.find((lane) => lane.id === laneId);

                if (shouldUpdateTicket && updatedTargetLane) {
                   // console.log(`Adding card ${cardToMove.id} to target lane ${updatedTargetLane.id}`);
                    updatedTargetLane.cards.unshift({
                        ...cardToMove,
                        ...updatedData,
                        id: cardToMove.id,
                    });
                } else {
                    console.error(`Target lane ${laneId} not found after removal!`);
                }
            } else {
                console.error(`Target lane ${laneId} not found!`);
            }

            
            //console.log('Final updatedBoard:', JSON.stringify(updatedBoard.lanes, null, 2));

        } else {

            //console.log('passed in changeTicket')

            if (shouldUpdateTicket) {
                updatedBoard.lanes = updatedBoard.lanes.map((lane) => {
                  //  console.log('Processing lane:', lane.id);
                    return {
                        ...lane,
                        cards: lane.cards.map((card) => {
                           // console.log('Processing card:', card.id, 'Matches ticketId?', card.id === Number(ticketId));
                            if (card.id === Number(ticketId)) {
                             //   console.log('Updating card:', card, 'with data:', updatedData);
                                return { ...updatedData, ...card.ticket };
                            }
                            return card;
                        }),
                    };
                });
            }
        }

    }
    // console.log('Updated Board:', updatedBoard);

    if (updatedBoard.lanes.some((lane) => lane.cards.length > 0)) {
        setFile(updatedBoard);
    } else {
        console.error('All lanes are empty after the update! This should not happen.');
    }
};

export const handleUpdateUnreadTicket = (file, setFile, ticketId, unreadMessages) => {
    if (!file || !file.lanes || !Array.isArray(file.lanes)) {
        console.error('File or lanes are not properly defined:', file);
        return; // Exit early if file or lanes are invalid
    }

    const updatedBoard = {
        ...file,
        lanes: file.lanes.map((lane) => {
            const ticketExists = lane.cards.some((card) => card.id === Number(ticketId));

            //console.log("Ticket exists in lane:", ticketExists, "Ticket ID:", ticketId);

            if (ticketExists) {
                return {
                    ...lane,
                    cards: lane.cards.map((card) =>
                        card.id === Number(ticketId)
                            ? { ...card, unreadMessages }
                            : card
                    ),
                };
            }

            return lane;
        }),
    };

    // Ensure there are no duplicate keys
    const allCardIds = updatedBoard.lanes.flatMap((lane) => lane.cards.map((card) => card.id));
    const uniqueCardIds = new Set(allCardIds);

    if (allCardIds.length !== uniqueCardIds.size) {
        console.warn(
            "Duplicate card IDs detected. This could cause rendering issues:",
            allCardIds.filter((id, index, arr) => arr.indexOf(id) !== index)
        );
    }

    setFile(updatedBoard);
};



export const handleDeleteTicketLane = (file, setFile, ticketId) => {
    if (!file || !file.lanes || !Array.isArray(file.lanes)) {
        // console.error('File or lanes are not properly defined:', file);
        return; // or handle this error appropriately
    }

    const updatedBoard = {
        ...file,
        lanes: file.lanes.map((lane) => ({
            ...lane,
            cards: lane.cards ? lane.cards.filter((card) => card.id !== ticketId) : [],
        })),
    };

    setFile(updatedBoard);
};
