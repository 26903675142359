import api from "../../../services/api";


export const fetchTicketsForLane = async ({
    pageNumber,
    showAll,
    queueIds,
    withUnreadMessages,
    tagIds,
    userIds,
    contactIds,
    statuses,
    laneId,
    connectionIds,
    dateFrom,
    dateTo,
    isGroup,
    sortDir,
    searchOnMessages,
    forceSearch,
    lastClientActivityMe,
    lastClientActivityClient,
    unreadMessagesSearchMe,
    unreadMessagesSearchClient,
    settingChange
}) => {
    try {
        //  console.log('params', params)
        const response = await api.get("/tickets", {
            params: {
                pageNumber,
                statuses,
                laneId,
                showAll,
                queueIds,
                withUnreadMessages,
                tagIds,
                userIds,
                contactIds,
                connectionIds,
                dateFrom,
                dateTo,
                isGroup,
                sortDir,
                searchOnMessages,
                forceSearch,
                lastClientActivityMe,
                lastClientActivityClient,
                unreadMessagesSearchMe,
                unreadMessagesSearchClient,
                settingChange,
                mode: 'kanban'
            },
        });
        //console.log(response, 'tes');
        return response.data;
    } catch (error) {
        console.error("Error fetching tickets:", error);
        throw error;
    }
};

export const updateTicketPosition = async (lanes) => {
    try {
        await api.post("/tickets/columns/change", { lanes });
    } catch (error) {
        console.error("Error updating ticket position:", error);
    }
};
