import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import SelectContacts from "../../components/SelectContacts";
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";


const filter = createFilterOptions({
    trim: false,
});

const NewTicketModal = ({ modalOpen, onClose, forContact }) => {
    const history = useHistory();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [newContact, setNewContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [whatsapp, setWhatsapp] = useState(null);
    const [whatsapps, setWhatsapps] = useState([]);
    const [showWhatsappSelection, setShowWhatsappSelection] = useState(false);
    const [searchParamQueue, setSearchParamQueue] = useState('');
    const [loadingQueue, setLoadingQueue] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const [queues, setQueues] = useState([user?.queues]);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const hasPermissionAddContact = user.roleId === 1 || permissions.some((permission) => permission.key === 'contacts:create');


    useEffect(() => {
        //console.log('forContact', forContact)
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    // console.log('contact', data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    useEffect(() => {
        if (forContact) {
            setSelectedContact(forContact);
        } else {
            setSelectedContact(null);
        }
    }, [forContact]);

    useEffect(() => {
        if (!modalOpen) {
            setLoadingQueue(false);
            return;
        }
        setLoadingQueue(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchQueues = async () => {
                try {
                    const { data } = await api.get("/queue/", {
                        params: { searchParam: searchParamQueue, whatsappId: whatsapp?.id },
                    });
                    user.roleId === 1 ? setQueues(data) : setQueues(user?.queues);
                    setLoadingQueue(false);
                } catch (err) {
                    setLoadingQueue(false);
                    toastError(err);
                }
            };

            fetchQueues();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParamQueue, whatsapp, modalOpen]);

    useEffect(() => {
        setLoading(true);
        setSelectedQueue(null);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("whatsapp");
                    setWhatsapps(data.whatsapps);
                    if (data.whatsapps.length > 1) {
                        setShowWhatsappSelection(true);
                    }
                    for (const item of data.whatsapps) {
                        if (item.isDefault) {
                            setWhatsapp(item);
                            break;
                        }
                    }
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [modalOpen]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedContact(null);
    };

    const handleSaveTicket = async () => {
        // console.log('contactId', selectedContact)
        if (!selectedContact) return;
        const hasPermission = permissions.some((permission) => permission.key === 'tickets:create:with:queue');
        // console.log(hasPermission, selectedQueue)
        if (hasPermission && !selectedQueue && user.roleId !== 1) {
            toast.error("Selecione um setor para criar um atendimento");
            return;
        }

        setLoading(true);
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: selectedContact.id,
                userId: user.id,
                status: "open",
                whatsappId: whatsapp ? whatsapp.id : null,
                queueId: selectedQueue ? selectedQueue.id : null
            });
            history.push(`/tickets/${ticket.id}`);

        } catch (err) {
            console.log('err', err)
            toastError(err);

        }
        setLoading(false);
        handleClose();
    };

    const handleSelectOption = (e, newValue) => {
        //console.log('o que deu',newValue)
        if (newValue) {
            if (newValue?.number) {
                setSelectedContact(newValue);
            } else if (newValue?.name && hasPermissionAddContact) {
                setNewContact({ name: newValue.name });
                setContactModalOpen(true);
            }
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContactTicket = contact => {
        console.log(contact, 'passed in here')
        setSelectedContact(contact);
        //handleSaveTicket();
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const hasPermission = user.roleId === 1 || permissions.some((permission) => permission.key === 'contacts:list:any');


    const renderOptionLabel = option => {
        // console.log('options', option)
        if (option.number && hasPermission) {
            return `${option.name} - ${option.number}`;
        } else if (option.number) {
            return `${option.name}`;
        } else {
            return hasPermissionAddContact ? `${i18n.t("newTicketModal.add")} ${option.name}`:`${i18n.t('Não encontrado')}`;
        }
    };

    const handleWhatsappChange = (event, whatsapp) => {
        setWhatsapp(whatsapp);
    }

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
            ></ContactModal>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("newTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    {forContact || selectedContact ?
                        <div>
                            <label style={{ fontSize: 15 }}>Contato:</label>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: 5 }}>
                                {forContact ?
                                    <><strong> {forContact.name} - </strong>
                                        <span>{hasPermission && (`${forContact.number}`)}</span></> :
                                    <><strong> {selectedContact.name} - </strong>
                                        <span>{hasPermission && (`${selectedContact.number}`)}</span>
                                        <IconButton size="small" onClick={() => setSelectedContact(null)}>
                                            <FaTimes />
                                        </IconButton>
                                    </>
                                }</div>
                        </div> :
                        <Autocomplete
                            options={options}
                            loading={loading}
                            style={{ width: 300 }}
                            clearOnBlur
                            autoHighlight
                            freeSolo
                            clearOnEscape
                            getOptionLabel={renderOptionLabel}
                            value={selectedContact}
                            isOptionEqualToValue={(option) => option}
                            //renderOption={renderOption}
                            filterOptions={createAddContactOption}
                            onChange={(e, newValue) => handleSelectOption(e, newValue)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={i18n.t("newTicketModal.fieldLabel")}
                                    variant="outlined"
                                    autoFocus
                                    onChange={e => setSearchParam(e.target.value)}
                                    onKeyUp={e => {
                                        if (loading || !selectedContact) return;
                                        else if (e.key === "Enter") {
                                            handleSaveTicket();
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />}
                    {showWhatsappSelection &&
                        <div style={{ marginTop: 10, flexWrap: 'wrap' }}>
                            <p style={{marginBottom: 10, fontWeight: 'bold', textAlign: 'center'}}>Selecione a conexão</p>
                            <ToggleButtonGroup color="primary"
                                value={whatsapp}
                                exclusive onChange={handleWhatsappChange} aria-label="select whatsapp"
                                size="small"
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gap: '10px', 
                                    maxWidth: '300px',
                                    
                                  }}
                            >
                                {whatsapps.map((item) => {
                                    return (<ToggleButton
                                            sx={{
                                                border: '1px solid !important',
                                                borderRadius: '5px !important',
                                            }}
                                        key={item.id} value={item}>{item.name}</ToggleButton>)
                                })}
                            </ToggleButtonGroup>
                        </div>}

                    {(forContact && !forContact.isGroup || !forContact) && <Autocomplete
                        style={{ width: 300, marginBottom: 10, marginTop: 20 }}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedQueue(newValue);
                        }}
                        options={queues}
                        filterOptions={filter}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("transferTicketModal.noOptions")}
                        loading={loadingQueue}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("transferTicketModal.fieldLabelQueue")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParamQueue(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("newTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact}
                        onClick={() => handleSaveTicket(selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("newTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketModal;
